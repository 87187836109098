import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import chat from '../data/data.json';

export default class Bar extends PureComponent {
  getOption = () => {
    return {
      tooltip : {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
            label: {
                show: true
            }
        }
    },
    toolbox: {
        show : true,
        feature : {
            magicType: {show: true, type: ['line', 'bar']},
            restore : {show: true},
            saveAsImage : {show: true}
        }
    },
    calculable : true,
    legend: {
        data:['Growth', 'Queenie', 'Cong'],
        itemGap: 5
    },
    grid: {
        top: '12%',
        left: '1%',
        right: '10%',
        containLabel: true
    },
    xAxis: [
        {
            type : 'category',
            data : chat.date
        }
    ],
    yAxis: [
        {
            type : 'value',
            name : 'Count'
        }
    ],
    dataZoom: [
        {
            show: true,
            start: 94,
            end: 100
        },
        {
            type: 'inside',
            start: 94,
            end: 100
        },
        {
            show: true,
            yAxisIndex: 0,
            filterMode: 'empty',
            width: 30,
            height: '80%',
            showDataShadow: false,
            left: '93%'
        }
    ],
    series : [
        {
            name: 'Queenie',
            type: 'bar',
            data: chat.queenie
        },
        {
            name: 'Cong',
            type: 'bar',
            data: chat.cong
        }
    ]
    };
  };
  render() {
    return (
      <div className='examples'>
        <div className='parent'>
          <label> Count: Cong and <strong>Queenie</strong> </label>
          <ReactEcharts
            option={this.getOption()}
            style={{height: '350px', width: '100%'}}
            className='react_for_echarts' />
        </div>
      </div>
    );
  }
}