import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';

export default class Cut extends PureComponent {
  getOption = () => {
    return {
      tooltip : {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
            label: {
                show: true
            }
        }
    },
    toolbox: {
        show : true,
        feature : {
            magicType: {show: true, type: ['line', 'bar']},
            restore : {show: true},
            saveAsImage : {show: true}
        }
    },
    calculable : true,
    legend: {
        data:[this.props.name],
        itemGap: 5
    },
    grid: {
        top: '12%',
        left: '1%',
        right: '10%',
        containLabel: true
    },
    xAxis: [
        {
            type : 'category',
            data : this.props.data.x
        }
    ],
    yAxis: [
        {
            type : 'value',
            name : 'Count'
        }
    ],
    dataZoom: [
        {
            show: true,
            start: 0,
            end: 2
        },
        {
            type: 'inside',
            start: 0,
            end: 2
        }
    ],
    series : [
        {
            name: this.props.name,
            type: 'bar',
            data: this.props.data.series
        }
    ]
    };
  };
  render() {
    return (
      <div className='examples'>
        <div className='parent'>
          <label> Count: <strong>{this.props.name}</strong> </label>
          <ReactEcharts
            option={this.getOption()}
            style={{height: '350px', width: '100%'}}
            className='react_for_echarts' />
        </div>
      </div>
    );
  }
}