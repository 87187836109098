import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import data from '../data/pie.json'

export default class Bar extends PureComponent {
  getOption = () => {
    return {
        tooltip : {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        toolbox: {
            show : true,
            feature : {
                saveAsImage : {show: true}
            }
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            data: data.map(d => d.name)
        },
        series : [
            {
                name: 'Rate',
                type: 'pie',
                radius : '55%',
                center: ['50%', '60%'],
                data,
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
  };
  render() {
    return (
      <div className='examples'>
        <div className='parent'>
          <label> Rate: Cong and <strong>Queenie</strong> </label>
          <ReactEcharts
            option={this.getOption()}
            style={{height: '350px', width: '100%'}}
            className='react_for_echarts' />
        </div>
      </div>
    );
  }
}