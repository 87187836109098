import React, { Component } from 'react';
import Bar from './components/Bar'
import Pie from './components/Pie'
import Scatter from './components/Scatter'
import Count from './components/Count'
import Cut from './components/Cut'
import './App.css';

import cutDataHe from './data/cut_he.json'
import cutDataMe from './data/cut_me.json'

class App extends Component {
  render() {
    return (
      <div className="App">
        <h1> Project <span className="PinkQ">Q</span> </h1>
        <Bar />
        <Pie />
        <Scatter />
        <Count />
        <Cut name="Queenie" data={cutDataHe} />
        <Cut name="Cong" data={cutDataMe} />
      </div>
    );
  }
}

export default App;
