import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import data from '../data/scatter.json';

export default class Scatter extends PureComponent {
  getOption = () => {
    return {
        tooltip: {
            position: 'top'
        },
        toolbox: {
            show : true,
            feature : {
                restore : {show: true},
                saveAsImage : {show: true}
            }
        },
        animation: false,
        grid: {
            height: '65%',
            // y: '10%'
        },
        xAxis: {
            type: 'category',
            data: data.x,
            splitArea: {
                show: true
            }
        },
        yAxis: {
            type: 'category',
            data: data.y,
            splitArea: {
                show: true
            }
        },
        dataZoom: [
            {
                show: true,
                start: 80,
                end: 100
            },
            {
                type: 'inside',
                start: 80,
                end: 100,
            }
        ],
        visualMap: {
            min: 0,
            max: 150,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '10%'
        },
        series: [{
            name: 'Chat Count',
            type: 'heatmap',
            data: data.data,
            label: {
                normal: {
                    show: false
                }
            },
            itemStyle: {
                emphasis: {
                    shadowBlur: 20,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }]
    };
  };
  render() {
    return (
      <div className='examples'>
        <div className='parent'>
          <label> Heatmap: Cong and <strong>Queenie</strong> </label>
          <ReactEcharts
            option={this.getOption()}
            style={{height: '550px', width: '100%'}}
            className='react_for_echarts' />
        </div>
      </div>
    );
  }
}